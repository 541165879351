const getNumberBitDepth = (value: any, fractional: number | string = 0, decimal: string = '.', thousands: string = ' '): String => {
  if (fractional === 'auto') {
    fractional = (String(value).split('.')?.[1] || '').length;
    if (fractional > 3) {
      fractional = 3
    }
  }

  fractional = Math.abs(Number.parseFloat(String(fractional)));
  fractional = isNaN(fractional) ? 2 : fractional;

  const negativeSign: string = value < 0 ? '-' : '';

  const i: any = parseInt(value = Math.abs(Number(value) || 0).toFixed(fractional)).toString();
  const j: any = (i.length > 3) ? i.length % 3 : 0;

  return String(negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) + (fractional ? decimal + Math.abs(value - i).toFixed(fractional).slice(2) : ''));
}

export {
  getNumberBitDepth
}
